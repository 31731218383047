import React from 'react'
import { GlobalContext } from '../../../context/GlobalContext'
import { Button, CardActionArea, CardMedia, Stack, TextField } from '@mui/material'

const ConfigComp = () => {
    const CON = React.useContext(GlobalContext)
    const [web, setWeb] = React.useState({})
    const [file, setFile] = React.useState("")

    async function updateWeb() {
        const fd = new FormData()
        fd.append('file', file)
        fd.append('app_name', web.app_name)
        fd.append('trial_days', web.trial_days)

        const res = await CON.hitAxios({
            path: "/api/admin/update_web",
            post: true,
            admin: true,
            obj: fd
        })
    }

    async function getWeb() {
        const res = await CON.hitAxios({
            path: "/api/admin/get_web",
            post: false,
            admin: true,
            token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_admin")
        })
        if (res.data.success) {
            setWeb(res.data.data)
        }
    }

    React.useEffect(() => {
        getWeb()
    }, [])

    return (
        web?.id &&
        <div>
            <Stack direction={'column'} spacing={2}>

                <CardActionArea component="label">
                    <input accept=".png,.jpg,.jpeg" onChange={(e) => setFile(e.target.files[0])} type="file" hidden />
                    <Stack alignItems={'center'}>
                        <CardMedia
                            loading="lazy"
                            alt="Web Logo"
                            component="img"
                            image={file ? URL.createObjectURL(file) : `/images/${web?.logo}`}
                            height={200}
                            sx={{ height: "100%", width: 200 }}
                        />
                    </Stack>
                </CardActionArea>


                <TextField
                    value={web.app_name}
                    onChange={(e) => setWeb({ ...web, app_name: e.target.value })}
                    label="App name" fullWidth size='small' InputProps={CON.inputProps} />

                <TextField
                    value={web.trial_days}
                    type='number'
                    helperText="These are days for new user signup trial put 0 if you dont want to give any trial days"
                    onChange={(e) => setWeb({ ...web, trial_days: e.target.value })}
                    label="Trial days" fullWidth size='small' InputProps={CON.inputProps} />

                <Button
                    onClick={updateWeb}
                    sx={{
                        background: 'linear-gradient(45deg, #E1306C 30%, #833AB4 70%)',
                        textTransform: 'none', borderRadius: 2,
                        color: 'white'
                    }}
                    variant='contained' >
                    Update Config
                </Button>
            </Stack>
        </div>
    )
}

export default ConfigComp