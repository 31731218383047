import { Box, Tabs, Typography, Tab, Container, Stack, IconButton, Divider } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types';
import { SpaceDashboard, PowerSettingsNew, PeopleAlt, Paid, ForwardToInbox, Tune, Quiz, VpnKey } from '@mui/icons-material'
import Users from './users/Users';
import Plans from './plans/Plans';
import SMTP from './smtp/SMTP';
import ConfigComp from './config/ConfigComp';
import Faq from './faq/Faq';
import DashComp from './DashComp';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const Dashboard = () => {
    const [value, setValue] = React.useState(0);
    const history = useHistory()
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return (
        <div>
            <Container sx={{ opacity: 0.95 }} maxWidth='lg'>
                <Stack pt={3} direction={'column'} spacing={2}>

                    <Stack alignItems={'center'} spacing={2} direction={'row'} justifyContent={'flex-end'}>
                        <Typography>👋🏻 Hello Admin!</Typography>
                        <IconButton
                            onClick={() => {
                                if (window.confirm("Are you sure?")) {
                                    localStorage.removeItem(process.env.REACT_APP_TOKEN + "_admin")
                                    history.push("/login")
                                }
                            }}
                        >
                            <PowerSettingsNew />
                        </IconButton>
                    </Stack>

                    <Box sx={{ width: '100%' }}>
                        <Box >
                            <Tabs
                                variant='scrollable'
                                TabIndicatorProps={{
                                    style: { display: 'none' }
                                }}
                                value={value} onChange={handleChange} aria-label="basic tabs example">

                                <Tab
                                    sx={{ textTransform: 'none' }}
                                    icon={<SpaceDashboard />} iconPosition='start' label="Dashboard" {...a11yProps(0)} />

                                <Tab
                                    sx={{ textTransform: 'none' }}
                                    icon={<PeopleAlt />} iconPosition='start' label="Users" {...a11yProps(1)} />

                                <Tab
                                    sx={{ textTransform: 'none' }}
                                    icon={<Paid />} iconPosition='start' label="Generate Activation Keys" {...a11yProps(2)} />

                                <Tab
                                    sx={{ textTransform: 'none' }}
                                    icon={<VpnKey />} iconPosition='start' label="SMTP" {...a11yProps(3)} />

                                <Tab
                                    sx={{ textTransform: 'none' }}
                                    icon={<Tune />} iconPosition='start' label="Configs" {...a11yProps(4)} />

                                <Tab
                                    sx={{ textTransform: 'none' }}
                                    icon={<Quiz />} iconPosition='start' label="Faq" {...a11yProps(5)} />

                            </Tabs>
                        </Box>
                        <Box borderRadius={4} minHeight={'80vh'} bgcolor={'#0C0F36'}>
                            <CustomTabPanel value={value} index={0}>
                                <DashComp />
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={1}>
                                <Users />
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={2}>
                                <Plans />
                            </CustomTabPanel>

                            <CustomTabPanel value={value} index={3}>
                                <SMTP />
                            </CustomTabPanel>

                            <CustomTabPanel value={value} index={4}>
                                <ConfigComp />
                            </CustomTabPanel>

                            <CustomTabPanel value={value} index={5}>
                                <Faq />
                            </CustomTabPanel>
                        </Box>
                    </Box>
                </Stack>
            </Container>
        </div>
    )
}

export default Dashboard