import React from 'react'
import { GlobalContext } from '../../context/GlobalContext'
import { Box, Stack, Typography } from '@mui/material'

const DashComp = () => {
    const [dash, setDash] = React.useState({})
    const { hitAxios } = React.useContext(GlobalContext)

    async function getDash() {
        const res = await hitAxios({
            path: "/api/admin/get_dash",
            post: false,
            admin: true,
            token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_admin")
        })
        if (res.data.success) {
            setDash(res.data)
        }
    }

    React.useEffect(() => {
        getDash()
    }, [])
    return (
        <div>
            <Stack direction={'row'} spacing={2}>
                <Box width={"33.3333333333%"} bgcolor={'rgba(245, 39, 145, 0.56)'} p={3}>
                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'flex-end'}>
                        <Typography variant='body2'>
                            Users
                        </Typography>
                        <Typography variant='h2'>
                            {dash?.totalUsers || "🧐"}
                        </Typography>
                    </Stack>
                </Box>

                <Box width={"33.3333333333%"} bgcolor={'rgba(245, 39, 145, 0.56)'} p={3}>
                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'flex-end'}>
                        <Typography variant='body2'>
                            Tasks
                        </Typography>
                        <Typography variant='h2'>
                            {dash?.totalTasks || "🧐"}
                        </Typography>
                    </Stack>
                </Box>

                <Box width={"33.3333333333%"} bgcolor={'rgba(245, 39, 145, 0.56)'} p={3}>
                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'flex-end'}>
                        <Typography variant='body2'>
                            Scrapped
                        </Typography>
                        <Typography variant='h2'>
                            {dash?.totalScrapped || "🧐"}
                        </Typography>
                    </Stack>
                </Box>
            </Stack>
        </div>
    )
}

export default DashComp