import axios from 'axios'
import React from 'react'
import { useHistory } from 'react-router-dom'

export const GlobalContext = React.createContext(null)

export const GlobalProvider = (props) => {
    const inputProps = { style: { borderRadius: 10 } }
    const url = process.env.REACT_APP_BASE_URL
    const [data, setData] = React.useState({
        loading: false,
        snack: false,
        snack_msg: "",
        campaign_done: false
    })
    const history = useHistory()

    function hexToRGBA(hex, opacity) {
        // Ensure the hex value is a valid 6-digit hex color code
        const hexRegex = /^#?([0-9a-fA-F]{6})$/;
        const match = hex.match(hexRegex);

        if (!match) {
            throw new Error("Invalid hex color code");
        }

        // Extract RGB values from the hex code
        const hexValue = match[1];
        const r = parseInt(hexValue.substring(0, 2), 16);
        const g = parseInt(hexValue.substring(2, 4), 16);
        const b = parseInt(hexValue.substring(4, 6), 16);

        // Ensure opacity is a valid value between 0 and 1
        if (opacity < 0 || opacity > 1) {
            throw new Error("Opacity must be a value between 0 and 1");
        }

        // Create and return the RGBA color string
        return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    }


    async function hitAxios({ path, obj, admin, post, token_user, loadingFalse }) {


        const token = admin ? localStorage.getItem(process.env.REACT_APP_TOKEN + "_admin") : localStorage.getItem(process.env.REACT_APP_TOKEN + "_user")


        setData({ ...data, loading: loadingFalse ? false : true })

        return new Promise(async (resolve, reject) => {
            try {
                const resp = post ? await axios.post(url + path, obj, {
                    headers: {
                        Authorization: "Bearer " + token
                    }
                }) : await axios.get(url + path, {
                    headers: {
                        Authorization: "Bearer " + token_user
                    }
                })

                if (resp?.data?.logout) {
                    admin ? localStorage.removeItem(process.env.REACT_APP_TOKEN + "_admin") : localStorage.removeItem(process.env.REACT_APP_TOKEN + "_user")
                    history.push(admin ? "/admin" : "/user")
                }

                if (!resp.data.success) {
                    console.log(resp.data)
                }

                if (!resp.data.success) {

                    setData({ ...data, loading: false, snack: resp?.data?.msg ? true : false, snack_msg: resp?.data?.msg || "Great", snack_success: false })
                    setTimeout(() => {
                        setData({ ...data, loading: false, snack: false, snack_msg: resp?.data?.msg || "Great", snack_success: false })
                    }, 4000);

                } else {
                    setData({ ...data, loading: false, snack: resp?.data?.msg ? true : false, snack_msg: resp?.data?.msg || "Great", snack_success: true })
                    setTimeout(() => {
                        setData({ ...data, loading: false, snack: false, snack_msg: resp?.data?.msg || "Great", snack_success: true })
                    }, 4000);
                }

                // setData({ ...data, loading: false, snack: resp?.data?.msg ? true : false, snack_msg: resp?.data?.msg || "Great" })
                // setTimeout(() => {
                //     setData({ ...data, loading: false, snack: false, snack_msg: resp?.data?.msg || "Great" })
                // }, 4000);

                resolve(resp)
            } catch (err) {
                console.log(err)
                setData({ ...data, snack_msg: JSON.stringify(err), snack: true, loading: false })
                setTimeout(() => {
                    setData({ ...data, snack_msg: "", snack: false, loading: false })
                }, 4000);
            }
        })
    }

    return (
        <GlobalContext.Provider value={{ data, setData, hitAxios, hexToRGBA, inputProps }}>
            {props.children}
        </GlobalContext.Provider>
    )
}

