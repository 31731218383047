import { Close } from '@mui/icons-material'
import { Box, Button, Dialog, Divider, IconButton, Stack, Switch, TextField, Typography } from '@mui/material'
import React from 'react'

const UserDialog = ({ state, setState, setEditUser, editUser, CON, getUsers }) => {

    async function updateUser() {
        const res = await CON.hitAxios({
            path: "/api/admin/update_user",
            post: true,
            admin: true,
            obj: editUser
        })
        if (res.data.success) {
            getUsers()
        }
    }

    return (
        <Dialog PaperProps={{ style: { borderRadius: 10, backgroundColor: "black" } }} open={state.dialog} fullWidth >
            <Box p={2}>
                <Stack spacing={2} direction={'column'}>
                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                        <Typography>Update User</Typography>
                        <IconButton onClick={() => setState({ ...state, dialog: false })} >
                            <Close />
                        </IconButton>
                    </Stack>
                    <Divider />

                    <Stack direction={'column'} spacing={2}>
                        <TextField
                            helperText="You can not change email"
                            disabled
                            value={editUser.email}
                            label="Email" fullWidth size='small' InputProps={CON.inputProps} />

                        <TextField

                            onChange={(e) => setEditUser({ ...editUser, newPassword: e.target.value })}
                            helperText="Leave it if you dont want to change your password"
                            label="Password" fullWidth size='small' InputProps={CON.inputProps} />

                        <TextField
                            onChange={(e) => setEditUser({ ...editUser, alert: e.target.value })}
                            multiline
                            rows={2}
                            helperText="This will show to user as alert. Leave it blank if nothing to be shown"
                            value={editUser.alert}
                            label="Alert" fullWidth size='small' InputProps={CON.inputProps} />

                        <Stack justifyContent={'space-between'} alignItems={'center'} direction={'row'} spacing={1}>
                            <Typography color={'gray'} variant='body2' >User email verify</Typography>
                            <Switch
                                onChange={(e) => setEditUser({ ...editUser, is_verified: e.target.checked ? 1 : 0 })}
                                checked={editUser?.is_verified > 0 ? true : false} />
                        </Stack>

                        <Button
                            onClick={updateUser}
                            sx={{
                                background: 'linear-gradient(45deg, #E1306C 30%, #833AB4 70%)',
                                textTransform: 'none', borderRadius: 2,
                                color: 'white'
                            }}
                            variant='contained' >
                            Update
                        </Button>
                    </Stack>
                </Stack>
            </Box>
        </Dialog>
    )
}

export default UserDialog