import { Cancel, Download } from '@mui/icons-material'
import { Box, Button, Chip, Container, Dialog, Grid, LinearProgress, Stack, Typography } from '@mui/material'
import React from 'react'
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

const UserTaskDialog = ({ state, setState, CON, editUser }) => {
    const [tasks, setTasks] = React.useState([])
    const [scrap, setScrap] = React.useState([])
    const [selTask, setSelTask] = React.useState({})


    const handleDownload = async (task) => {
        CON.setData({ ...CON.data, loading: true })
        try {
            const token = localStorage.getItem(process.env.REACT_APP_TOKEN + "_admin")
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/admin/export_task`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    task_id: task?.task_id
                }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }


            // Convert the response to a blob
            const blob = await response.blob();

            // Create a link element and trigger the download
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${task?.task_id}.xlsx`;


            CON.setData({ ...CON.data, loading: false })
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading Excel file:', error);
        }
    };

    async function getTasks() {
        const res = await CON.hitAxios({
            path: "/api/admin/get_all_task_user",
            post: true,
            admin: true,
            obj: { uid: editUser?.uid }
        })
        if (res.data.success) {
            setTasks(res.data.data)
        }
    }


    React.useEffect(() => {
        getTasks()
    }, [editUser])

    return (
        <Dialog
            PaperProps={{
                style: { backgroundColor: "black" }
            }}
            fullScreen open={state.dialogTask} >
            <Button
                onClick={() => setState({ ...state, dialogTask: false })}
                startIcon={<Cancel />} >
                Close
            </Button>

            {CON.data.loading && <LinearProgress />}

            <Box mt={4}>
                <Container maxWidth='lg'>
                    <Box p={2} borderRadius={2} bgcolor={'action.hover'}>

                        <Box mb={2} >
                            <Typography color={'gray'} variant='caption' >Click on a task chip to show data</Typography>
                        </Box>
                        <Stack direction={'row'} spacing={2}>
                            {tasks.map((i, key) => {
                                return (
                                    <Chip
                                        onClick={() => handleDownload(i)}
                                        color='secondary'
                                        icon={<Download />}
                                        sx={{ mb: 2 }}
                                        key={key}
                                        variant={selTask?.id === i.id ? "filled" : 'outlined'}
                                        label={i?.title} />
                                )
                            })}
                        </Stack>

                    </Box>
                </Container>
            </Box>
        </Dialog>
    )
}

export default UserTaskDialog