import React from 'react'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import HomeMainPage from './HomeMainPage'
import { GlobalProvider } from './context/GlobalContext';

export default function ToggleColorMode() {

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: 'dark',
        },
      }),
    [],
  );



  return (

    <GlobalProvider>
      <ThemeProvider theme={theme}>
        <HomeMainPage />
      </ThemeProvider>
    </GlobalProvider>

  );
}