import React from 'react'
import { GlobalContext } from '../../../context/GlobalContext'
import { Box, Button, IconButton, Stack, TextField, Typography } from '@mui/material'
import { CopyAll, Delete, DeleteOutline, Key } from '@mui/icons-material'
import { DataGrid } from '@mui/x-data-grid';

const Plans = () => {
    const [state, setState] = React.useState({})
    const [keys, setKeys] = React.useState([])
    const inputProps = { style: { borderRadius: 10 } }
    const { hitAxios } = React.useContext(GlobalContext)

    async function getKeys() {
        const res = await hitAxios({
            path: "/api/admin/get_keys",
            post: false,
            admin: true,
            token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_admin")
        })
        if (res.data.success) {
            setKeys(res.data.data)
        }
    }

    async function addKey() {
        const res = await hitAxios({
            path: "/api/admin/add_activation_key",
            admin: true,
            post: true,
            obj: state
        })
        if (res.data.success) {
            setState({})
            getKeys()
        }
    }

    async function delKey(id) {
        if (window.confirm("Are you sure?")) {
            const res = await hitAxios({
                path: "/api/admin/del_key",
                post: true,
                admin: true,
                obj: { id }
            })
            if (res.data.success) {
                getKeys()
            }
        }
    }

    React.useEffect(() => {
        getKeys()
    }, [])

    return (
        <div>
            <Stack direction={'column'} spacing={2} >
                <Stack direction={'row'} spacing={2}>
                    <TextField
                        helperText="Enter the number of days when you want to expire the plan after using this key"
                        size='small'
                        InputProps={inputProps}
                        value={state.expiry_timestamp}
                        type='number'
                        onChange={(e) => setState({ ...state, expiry_timestamp: e.target.value })}
                        label="Credit expiry" fullWidth />

                    <TextField
                        helperText="Enter the number of days when you want the keys to expire"
                        size='small'
                        InputProps={inputProps}
                        value={state.usage_validity_timestamp}
                        type='number'
                        onChange={(e) => setState({ ...state, usage_validity_timestamp: e.target.value })}
                        label="Keys validity" fullWidth />
                </Stack>

                <TextField
                    size='small'
                    InputProps={inputProps}
                    value={state.small_description}
                    multiline
                    rows={2}
                    onChange={(e) => setState({ ...state, small_description: e.target.value })}
                    label="Small description" fullWidth />

                <Button
                    startIcon={<Key />}
                    onClick={addKey}
                    sx={{
                        background: 'linear-gradient(45deg, #E1306C 30%, #833AB4 70%)',
                        textTransform: 'none', borderRadius: 2,
                        color: 'white'
                    }}
                    variant='contained' >
                    Generate Key
                </Button>
            </Stack>

            <Box mt={4}>
                {keys ?
                    <Box height='80vh'>
                        <DataGrid
                            getRowId={(row) => row.id}
                            rows={[...keys].reverse()}
                            columns={[
                                { headerName: "Keys", field: 'activation_key', flex: 1 },
                                { headerName: "Keys expire Days", field: 'expiry_timestamp', flex: 1 },
                                { headerName: "Keys validity Days", field: 'usage_validity_timestamp', flex: 1 },
                                {
                                    headerName: "Used", field: 'used', flex: 1, renderCell: data => {
                                        return (
                                            data.row.used ? "✅" : "NO"
                                        )
                                    }
                                },
                                {
                                    headerName: "Used by", field: 'used_by', flex: 1, renderCell: data => {
                                        return (
                                            data?.row?.used_by ? JSON.parse(data?.row?.used_by)?.email : "NA"
                                        )
                                    }
                                },
                                {
                                    headerName: "Copy Key", field: 'copy', flex: 1, renderCell: data => {
                                        return (
                                            <IconButton
                                                onClick={() => window.navigator.clipboard.writeText(data.row.activation_key)} >
                                                <CopyAll />
                                            </IconButton>
                                        )
                                    }
                                },
                                {
                                    headerName: "Delete", field: 'del', flex: 1, renderCell: data => {
                                        return (
                                            <IconButton
                                                color='error'
                                                onClick={() => delKey(data.row.id)} >
                                                <Delete />
                                            </IconButton>
                                        )
                                    }
                                },
                            ]}
                            pageSize={50}
                            rowsPerPageOptions={[50]}
                            checkboxSelection={false}
                            sx={{
                                boxShadow: 0,
                                borderRadius: 4,
                                border: 0,
                                fontSize: 13,
                                padding: 2
                            }}
                        />
                    </Box> : ""
                }
            </Box>
        </div>
    )
}

export default Plans