import { Box, Button, Container, Grid, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import { GlobalContext } from '../../context/GlobalContext'
import { Email, EmailOutlined, Fingerprint, Visibility, VisibilityOff } from '@mui/icons-material'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const AdminLogin = () => {
    const CON = React.useContext(GlobalContext)
    const history = useHistory()
    const [state, setState] = React.useState({
        visibility: false
    })

    async function handleClick() {
        const res = await CON.hitAxios({
            path: "/api/admin/login",
            post: true,
            admin: true,
            obj: state
        })
        if (res.data.success) {
            localStorage.setItem(process.env.REACT_APP_TOKEN + "_admin", res.data.token)
            history.push('/admin')
        }
    }

    const [position, setPosition] = React.useState({ left: '', top: '' });

    const handleMouseOver = () => {
        setPosition({
            left: `${Math.ceil(Math.random() * 90)}%`,
            top: `${Math.ceil(Math.random() * 90)}%`,
        });
    };



    return (
        <div>
            <Container maxWidth='lg'>
                <Grid minHeight={'100vh'} container alignItems={'center'} justifyContent={'center'}>
                    <Grid sx={12} sm={6} lg={4} item>
                        <Grid container spacing={2} direction={'column'}>
                            <Grid item>
                                <Stack direction={'column'}>
                                    <Typography variant='h4' >👋🏻 Hello Admin!</Typography>

                                    <Typography color={'orange'} variant='caption' >Try to click 'Login' without entering email and password 😈</Typography>
                                </Stack>
                            </Grid>
                            <Grid item>
                                <TextField
                                    onChange={(e) => setState({ ...state, email: e.target.value })}
                                    value={state.email}
                                    InputProps={{ ...CON.inputProps, startAdornment: <InputAdornment position='start'><EmailOutlined /></InputAdornment> }}
                                    label="Email" fullWidth size='small' />
                            </Grid>
                            <Grid item>
                                <TextField
                                    onChange={(e) => setState({ ...state, password: e.target.value })}
                                    value={state.password}
                                    type={state.visibility ? 'text' : 'password'}
                                    InputProps={{
                                        ...CON.inputProps, startAdornment: <InputAdornment position='start'><Fingerprint /></InputAdornment>,
                                        endAdornment: <InputAdornment position='end' >
                                            <IconButton onClick={() => setState({ ...state, visibility: !state.visibility })} >
                                                {state.visibility ?
                                                    <Visibility /> :
                                                    <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }}
                                    label="Password" fullWidth size='small' />
                            </Grid>
                            <Grid item>
                                {state.email && state.password ?
                                    <Button
                                        onClick={handleClick}
                                        variant='contained'
                                        sx={{ textTransform: 'none', borderRadius: 2 }} >
                                        <a style={{ fontSize: 18 }} >😇</a> Login
                                    </Button> :

                                    <Button
                                        color='secondary'
                                        id="btn"
                                        style={{
                                            position: 'absolute',
                                            transition: '.5s',
                                            padding: '10px',
                                            height: '40px',
                                            width: '150px',
                                            left: position.left,
                                            top: position.top,
                                        }}
                                        onMouseOver={handleMouseOver}
                                        variant='contained'
                                        sx={{ textTransform: 'none', borderRadius: 2 }} >
                                        <a style={{ fontSize: 18 }} >🤯</a> Login
                                    </Button>}

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </Container>
        </div>
    )
}

export default AdminLogin