import React from 'react'
import { GlobalContext } from '../../../context/GlobalContext'
import { Box, IconButton, LinearProgress } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment'
import { Delete, Edit, Visibility } from '@mui/icons-material';
import UserDialog from './UserDialog';
import UserTaskDialog from './UserTaskDialog';

const Users = () => {
    const CON = React.useContext(GlobalContext)
    const [editUser, setEditUser] = React.useState({})
    const [state, setState] = React.useState({
        dialog: false
    })
    const [users, setUsers] = React.useState("")

    async function getUsers() {
        const res = await CON.hitAxios({
            path: "/api/admin/get_users",
            post: false,
            admin: true,
            token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_admin")
        })
        if (res.data.success) {
            setUsers(res.data.data)
        }
    }

    async function delUsers(id) {
        if (window.confirm("Are you sure?")) {
            const res = await CON.hitAxios({
                path: "/api/admin/del_user",
                post: true,
                admin: true,
                obj: { id }
            })
            if (res.data.success) {
                getUsers(res.data.data)
            }
        }
    }

    React.useEffect(() => {
        getUsers()
    }, [])

    return (
        <div>
            <UserTaskDialog CON={CON} editUser={editUser} setEditUser={setEditUser} state={state} setState={setState} getUsers={getUsers} />
            <UserDialog CON={CON} editUser={editUser} setEditUser={setEditUser} state={state} setState={setState} getUsers={getUsers} />
            {users ?
                <Box height='80vh'>
                    <DataGrid
                        getRowId={(row) => row.id}
                        rows={[...users].reverse()}
                        columns={[
                            { headerName: "Email", field: 'email', flex: 1 },
                            {
                                headerName: "Is Verified", field: 'is_verified', flex: 1, renderCell: data => {
                                    return (
                                        data.row.is_verified ? "YES" : "NO"
                                    )
                                }
                            },
                            {
                                headerName: "User Alert", field: 'alert', flex: 1, renderCell: data => {
                                    return (
                                        data.row.alert || "NA"
                                    )
                                }
                            },
                            {
                                headerName: "Plan", field: 'plan', flex: 1, renderCell: data => {
                                    return (
                                        data.row.plan || "NA"
                                    )
                                }
                            },
                            {
                                headerName: "Plan Expiry", field: 'plan_expire', flex: 1, renderCell: data => {
                                    return (
                                        data.row.plan_expire || "NA"
                                    )
                                }
                            },
                            {
                                headerName: "Signed up", field: 'createdAt', flex: 1, renderCell: data => {
                                    return (
                                        moment(data.row.createdAt).format("DD-MM-YYYY")
                                    )
                                }
                            },
                            {
                                headerName: "Update", field: 'id', flex: 1, renderCell: data => {
                                    return (
                                        <IconButton
                                            onClick={() => {
                                                setEditUser(data.row)
                                                setState({ ...state, dialog: true })
                                            }}
                                        >
                                            <Edit />
                                        </IconButton>
                                    )
                                }
                            },
                            {
                                headerName: "User Task(s)", field: 'task', flex: 1, renderCell: data => {
                                    return (
                                        <IconButton
                                            color='info'
                                            onClick={() => {
                                                setEditUser(data.row)
                                                setState({ ...state, dialogTask: true })
                                            }}
                                        >
                                            <Visibility />
                                        </IconButton>
                                    )
                                }
                            },
                            {
                                headerName: "Delete", field: 'del', flex: 1, renderCell: data => {
                                    return (
                                        <IconButton
                                            onClick={() => delUsers(data.row.id)}
                                            color='error' >
                                            <Delete />
                                        </IconButton>
                                    )
                                }
                            },
                        ]}
                        pageSize={50}
                        rowsPerPageOptions={[50]}
                        checkboxSelection={false}
                        sx={{
                            boxShadow: 0,
                            borderRadius: 4,
                            border: 0,
                            fontSize: 13,
                            padding: 2
                        }}
                    />
                </Box> : <LinearProgress />
            }
        </div>
    )
}

export default Users