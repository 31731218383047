import React from 'react'
import { GlobalContext } from '../../../context/GlobalContext'
import { Button, Stack, TextField } from '@mui/material'

const SMTP = () => {
    const CON = React.useContext(GlobalContext)
    const [smtp, setSmtp] = React.useState({})

    async function updateSMTP() {
        const res = await CON.hitAxios({
            path: "/api/admin/update_smtp",
            post: true,
            admin: true,
            obj: smtp
        })
    }

    async function getSMTP() {
        const res = await CON.hitAxios({
            path: "/api/admin/get_smtp",
            post: false,
            admin: true,
            token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_admin")
        })
        if (res.data.success) {
            setSmtp(res.data.data)
        }
    }

    React.useEffect(() => {
        getSMTP()
    }, [])

    return (
        smtp?.id &&
        <div>
            <Stack direction={'column'} spacing={2}>
                <TextField
                    onChange={(e) => setSmtp({ ...smtp, email: e.target.value })}
                    value={smtp.email}
                    label="Email" fullWidth size='small' InputProps={CON.inputProps} />
                <TextField
                    onChange={(e) => setSmtp({ ...smtp, password: e.target.value })}
                    value={smtp.password}
                    label="Password" fullWidth size='small' InputProps={CON.inputProps} />
                <TextField
                    type='number'
                    onChange={(e) => setSmtp({ ...smtp, port: e.target.value })}
                    value={smtp.port}
                    label="Port" fullWidth size='small' InputProps={CON.inputProps} />
                <TextField
                    onChange={(e) => setSmtp({ ...smtp, host: e.target.value })}
                    value={smtp.host}
                    label="Host" fullWidth size='small' InputProps={CON.inputProps} />


                <Button
                    onClick={updateSMTP}
                    sx={{
                        background: 'linear-gradient(45deg, #E1306C 30%, #833AB4 70%)',
                        textTransform: 'none', borderRadius: 2,
                        color: 'white'
                    }}
                    variant='contained' >
                    Update Smtp
                </Button>
            </Stack>
        </div>
    )
}

export default SMTP